import dayjs from '@/lib/dayjs'
import { lineColumnChartOptions } from '@/config/dashboard'
import {
  getOrgChartData,
  truncateDecimal,
  getDateOfRange,
} from '@/utils/dashboard'
import { getCssVar } from '@/utils/helper'
import { values } from 'lodash'

const yaxisTitleConfig = {
  sumAmount: '金額',
  count: '單數',
  avgAmount: '金額',
  avgMemberCount: '單數',
}

export const generateAgeConfig = (labels = [], series = [], yaxisTitle) => ({
  ...lineColumnChartOptions,
  colors: [
    getCssVar('chart-1'),
    getCssVar('chart-2'),
    getCssVar('chart-3'),
    getCssVar('chart-4'),
    getCssVar('chart-5'),
    getCssVar('chart-6'),
  ],
  xaxis: {
    type: 'date',
    title: {
      text: '年齡',
    },
  },
  yaxis: [
    {
      showAlways: true,
      opposite: true,
      title: {
        text: '人數',
      },
    },
    {
      showAlways: true,
      title: {
        text: yaxisTitle,
      },
    },
  ],
  dataLabels: {
    enabled: false,
  },
  labels,
  series,
})

const labels = [
  '20以下',
  '21-25',
  '26-30',
  '31-35',
  '36-40',
  '41-45',
  '46-50',
  '51-55',
  '56-60',
  '61-65',
  '65+',
  '其他',
]

const initDataSet = (typeKeys) => {
  const obj = {}
  typeKeys.forEach((item) => {
    obj[item] = {
      name: item,
      type: 'line',
      data: labels.map(() => 0),
    }
  })
  return obj
}

export const getAgeData = async ({
  orgId,
  interval,
  target,
  typeKeys,
  splitType,
}) => {
  const dateRange = getDateOfRange({
    interval,
    splitType,
  })

  const { result, lastUpdatedAt } = await getOrgChartData({
    orgId,
    key: 'orgTransactionRateByAgeAndType',
    dateTrunc: splitType,
    createdAtStart: dateRange.createdAtStart,
    createdAtEnd: dateRange.createdAtEnd,
  })

  const dataset = initDataSet(typeKeys)

  result.forEach((item) => {
    const ageIndex = labels.findIndex((key) => key === item.age_range)
    if (dataset[item.type]) {
      dataset[item.type].data[ageIndex] = truncateDecimal(item[target])
    }
  })

  const series = Object.values(dataset)

  const memberCountByAgeRange = {}
  result.forEach(item => {
    const ageRange = item.age_range
    const memberCount = item.memberCount

    // 檢查 ageRange 是否在 labels 中
    if (labels.includes(ageRange)) {
      // 如果 ageRange 已存在於 memberCountByAgeRange 中，則將 memberCount 累加上去，否則創建新的屬性
      if (memberCountByAgeRange[ageRange]) {
        memberCountByAgeRange[ageRange] += memberCount
      } else {
        memberCountByAgeRange[ageRange] = memberCount
      }
    }
  })

  labels.forEach(label => {
    if (!memberCountByAgeRange[label]) memberCountByAgeRange[label] = 0
  })

  const labelData = []
  labels.forEach(label => {
    labelData.push(memberCountByAgeRange[label])
  })

  //  最後一項把前面數值加總，api還沒回人數

  series.unshift({
    name: '全渠道人數',
    type: 'column',
    // data: result.length && [result[0].memberCount],
    data: labelData,
  })
  const yaxisTitle = yaxisTitleConfig[target]

  return {
    newOptions: generateAgeConfig(labels, series, yaxisTitle),
    lastUpdatedAt,
  }
}
